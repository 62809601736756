<template>
    <div class="page">
        <Header />
        
        <div v-if="success" class = "success">
            <div>We have received your request!</div>
            <div>One of our Techs will reach out to you as soon as they are available</div>
        </div>
        
        <h1>Setup an Appointment Today!</h1>

        <h3>Tell us a little about yourself</h3>
        <h4>First Name: </h4>
        <input name = "fname" type = "text" v-model="firstname" />

        <h4>Last Name: </h4>
        <input name = "lname" type = "text" v-model="lastname"/>

        <h4>Phone Number: </h4>
        <input name = "phone" type="tel" v-model="phonenumber"/>

        <h4>Email: </h4>
        <input name = "email" type = "text" v-model="email"/>

        <h4>Prefered Contact Method:</h4>

        <label><input type = "checkbox" v-model="prefcall" /> Call</label>
        <label><input type = "checkbox" v-model="preftext" /> Text</label>
        <label><input type = "checkbox" v-model="prefemail" /> Email</label>

        <h3>Tell us a bit more about your problem</h3>

        <h4>Device:</h4>
        <input type = "text" v-model="device"/>

        <h4>Short Description of Issue:</h4>
        <input type = "text" v-model="description"/>

        <h4>When Would You Like Service:</h4>
        <input type = "date" v-model="dateservice"/>

        <div class = "info">
            <div>We will do our best to service you by the date selected, however your confirmation will list the date that we will actually be able to complete the service by</div>
            <div>If you would like the service rushed, in some instances it can be done sooner but there will be an additional cost</div>
            <div>If you would like for us to attempt to rush the service to meet your deadline and understand that there will be an additional charge, check the box below:</div>
        </div>

        <label><input type = "checkbox" v-model="rushservice"/>Rush Services</label>

        <div class = "info">
            <div>You can drop off and pick up from our shops for free</div>
            <div>If you would like our tech to stop by and pick up the equipment you need serviced from your home there are additional fees</div>
            <div>As a PC Refresh Plus member enjoy free pick up and drop off services (fuel surcharge may still be charged) - Ask your tech about our PC Refresh Membership and save on this appointment when you sign up</div>
            <div>If you would prefer that we pick up and drop off the tech from your home and understand that there will be an additional charges, check the box below:</div>
        </div>

        <label><input type = "checkbox" v-model="pickup"/>Pick Up and Drop Off Services</label>

        <div class = "info">
            <div>Many services do not require our techs to be in your home to perform and can be performed in our shop</div>
            <div>However there are some situations where our tech will have to be at your home to provide the service which is a $50 charge</div>
            <div>As a PC Refresh Plus member this charge is waived - Ask your tech about our PC Refresh Membership and save on this appointment when you sign up</div>
            <div>We will assess whether or not we need to perform the service in your home when you submit your request and inform you in the appointment confirmation</div>
        </div>

        <h4>Want to save on your first appointment?  Consider joining our PC Refresh program for $20 off your first service as a member</h4>
        <h4>Ask your tech about joining the easiest way to keep your devices running smooth!</h4>

        <div v-if="error" class = "error">Make sure to fill out all of the fields above before requesting an appointment!</div>
        
        <div class = "button" @click="submit()">Request Appointment</div>

        <div class = "info">Appointments will be confirmed by one of the prefered methods of contact by one of our techs - Make sure your appointment is confirmed before delivering equipment to the shop!</div>
        <div class = "info">For our most up to date policy on cancellation and rescheduling, please review our Cancellation Policy <router-link to="/Cancellation-Policy">here</router-link></div>
        <div class = "info">Your personal information is held in accordance with our privacy policy which can be found <router-link to="/Privacy">here</router-link> - We never sell your data!</div>
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
    name: 'Appointment',
    components:{
        Header,
        Footer
    },
    data : function(){
        return{
            firstname : "",
            lastname : "",
            phonenumber : "",
            email : "",
            prefcall : false,
            preftext : false,
            prefemail : false,
            device : "",
            description : "",
            dateservice : null,
            rushservice : false,
            pickup : false,
            error : false,
            success : false
        }
    },
    methods : {
        getParameter(search){
            var result = null, tmp = [];
            location.search
                .substr(1)
                .split("&")
                .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === search) result = decodeURIComponent(tmp[1]);
                });
            return result;
        },
        submit(){
            this.success = false;

            if(this.checkInputs()){
                fetch("https://danstechservices.us:3000/api/requestappointment", { method: "POST", headers : { orderdata : JSON.stringify({
                    firstname : this.firstname,
                    lastname : this.lastname,
                    phonenumber : this.phonenumber,
                    email : this.email,
                    prefcall : this.prefcall,
                    preftext : this.preftext,
                    prefemail : this.prefemail,
                    device : this.device,
                    description : this.description,
                    dateservice : this.dateservice,
                    rushservice : this.rushservice
                }) } })
                .then( () => this.clearInputs() );
            }
        },
        checkInputs(){
            if(this.firstname == "" || this.lastname == "" || this.phonenumber == "" || this.email == "" || this.device == "" || this.description == "" || this.dateservice == null ){
                this.error = true;
                return false;
            }
            else return true;
        },
        clearInputs(){
            this.success = true;
            this.error = false;
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    },
    mounted : function(){
        this.device = this.getParameter("device");
        this.description = this.getParameter("service");
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1{
    font-size: 36px;
}

h3{
    font-size: 24px;
}

h4{
    font-size: 18px;
}

input{
    width: 50%;
    padding: 15px;
    border: none;
    border: 1px solid #000;
    border-radius: 7px;
    text-align: center;
    font-size: 18px;
}

input[type="checkbox"]{
    width: 24px;
    height: 24px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

.page{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #e6e6e6;
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
}

.info{
    font-size: 14px;
    margin: 20px;
}

.button{
    background: none;
	color: inherit;
	border: none;
	font: inherit;
	outline: inherit;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    border-radius: 7px;
    background-color: rgb(105, 236, 214);
    width: 20%;
    transition: ease-in-out 0.2s;
    cursor: pointer;
}

.button:hover{
    background-color: rgb(155, 187, 235);
}

.error{
    color: #da4646;
    font-weight: bold;
}

.success{
    background-color: #a2dda2;
    border-radius: 7px;
    width: 50%;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
    margin: 10px auto 0 auto;
}

@media only screen and (max-width: 600px) {
    .button {
        width: 80%;
    }

    input{
        width: 80%;
    }
}
</style>